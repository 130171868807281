@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Nunito:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&family=Josefin+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.buy_sec {
  display: flex;
  flex-direction: column;
  background-color: #f7f6fd;
}
.loginInput {
  width: 75%;
  outline: none;
  margin: auto;
  border: none;
  background-color: #f7f7f7;
  margin-top: 1em;
  padding: 1em;
  border-radius: 20px;
  height: 4em;
}
.inputContainer {
  width: 90%;
  margin: auto;
}
.input_box {
  width: 55%;
  margin: auto;
  height: 3em;
  border-radius: 15px;
  outline: none;
  font-size: 25px;
  padding: 10px;
  border: none;
}
.horizontal_flex {
  display: flex;
  flex-direction: row;
}
.tableNameContainer {
  display: flex;
  flex-direction: row;
  width: 20%;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: space-between;
}
.welcomeCard {
  height: fit-content;
  width: 95%;
  background-color: #ffa001;
  border-radius: 30px;
  margin: 2%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 1em;
}
.dashboardCard {
  min-width: 200px;
  height: 8em;
  background-color: #32297c;
  padding: 20px;
}
.dashboardContainer {
  width: 50%;
  position: relative;
  margin-left: 10em;
  min-width: 500px;
}
.sideNavBar {
  position: fixed;
  height: 95vh;
  display: flex;
  flex-direction: column;
  margin: 1em;
  border-radius: 30px;
  width: 8em;
  background-color: #32297c;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.list {
  overflow: auto;
}
.transaction_sec {
  background-color: #f7f6fd;
  padding: 2em;
  height: 90vh;
  margin: 2em;
  border-radius: 30px;
  width: 35%;
  position: fixed;
  right: 0;
  min-width: 500px;
}
.transaction_sec_trade {
  background-color: #f7f6fd;
  padding: 2em;
  height: 90vh;
  margin: 2em;
  border-radius: 30px;
  width: 35%;
  position: fixed;
  right: 0;
  min-width: 500px;
}
.footer {
  width: 100%;
  height: 5em;
  margin-top: 1em;
}
.title {
  text-align: center;
  width: 9em;
}
footer {
  width: 100%;
  height: 5em;
  margin-top: 1em;
}
.titleAmount {
  text-align: center;
  width: 9em;
}
.titleTotal {
  text-align: center;
  width: 9em;
}

.titleName {
  text-align: center;
}
.titlePrice {
  width: 10em;
  text-align: center;
}
.titleNumber {
  text-align: center;
  font-size: 15px;
}
.loginBox {
  display: flex;
  flex-direction: row;
}
.loginArt {
  width: 65%;
  background-color: #ffe3b4;
  height: 100%;
}
.dashboardPreview {
  width: 70%;
  height: 90%;
  position: relative;
  margin: auto;
  display: block;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.loginContainer {
  width: 35%;
}
.dashboardBox {
  display: flex;
  flex-direction: column;
}
.balanceCards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: auto;
}

@media only screen and (max-width: 1350px) {
  .transaction_sec_trade {
    display: none;
  }
  .transaction_sec {
    background-color: #f7f6fd;
    padding: 2em;
    height: 90vh;
    margin: auto;
    border-radius: 30px;
    width: fit-content;
    position: relative;
    right: 0;
  }
  .dashboardBox {
    display: flex;
    flex-direction: column;
  }
  .balanceCards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin: auto;
  }
  .welcomeCard {
    width: 95%;
  }
  .sideNavBar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: auto;
    border-radius: 0px;
    width: 100%;
    background-color: #32297c;
    padding: 1em;
    height: fit-content;
    min-width: 500px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .dashboardContainer {
    width: 60%;
    position: relative;
    margin: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .loginBox {
    display: flex;
    flex-direction: column;
  }
  .loginArt {
    width: 100%;
    background-color: #ffe3b4;
    height: 100%;
  }
  .loginContainer {
    width: 100%;
    margin: auto;
  }
  .dashboardPreview {
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
    margin-top: 1em;
    display: block;
  }
  .dashboardContainer {
    width: 80%;
    position: relative;
    margin: auto;
  }
}
@media only screen and (max-width: 800px) {
  .sideNavBar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 0px;
    margin: auto;
    width: 100%;
    background-color: #32297c;
    padding: 1em;
    height: fit-content;
    min-width: 500px;
  }
  .dashboardContainer {
    width: 100%;
    position: relative;
    margin: auto;
  }
  .loginInput {
    width: 90%;
  }
}
tr {
  background-color: #f7f6fd;
  margin: 1em;
  padding: 2em;
  border-radius: 30px;
}
th,
td {
  width: 150px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}
td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
.gfg {
  border-collapse: separate;
  border-spacing: 0 15px;
}
tr.spaceUnder > td {
  padding: 1em;
}
th {
  background-color: #fcfdff;
}
.switch {
  border-radius: 0%;
}
#outlined-select-currency-native-helper-text {
  background-color: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.tableHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.name {
  color: #f3f0f5;
  font-family: Noto Sans, sans-serif;
  font-weight: 400px;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  width: 100%;
}
.buy {
  width: 40%;
}
.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.row {
  background-color: #5f7ce3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  border-radius: 20px;
  margin: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.verticalFlex {
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}
.dogeimg {
  animation: move 5s infinite;
}
@keyframes move {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 5s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 2s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 2s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
